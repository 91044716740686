import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css'
import { ThemeProvider, CssBaseline, createTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes, useNavigate, } from 'react-router-dom';
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'
import Header from './Header/Header';
import SharcList from './Body Sections/Device List/SharcList';
import SharcInfo from './Body Sections/Selected Sharc Info/SharcInfo';
import Navbar from './Body Sections/Navigation/Navbar';
import Footer from './Footer/Footer';
import LiveChart from './Body Sections/Live Chart/LiveChart';
import Login from './Login/Login';
import AuthenticatedRoute from './Body Sections/AuthenticatedRoute/AuthenticatedRoute';
import AdoptList from './Body Sections/Adopt/AdoptList';
import Metrics from './Body Sections/Metrics/Metrics';
import AccountCreation from './Login/Account Creation/AccountCreation';
import AutoAdopt from './Body Sections/AutoAdopt/AutoAdopt'

// const dev = false;
// const apiIp = dev ? 'localhost' : '192.168.111.132';
// const apiPort = '8888';

const endpointsV2 = {
  login: `${window._env_.REACT_APP_API_PROTOCOL}://${window._env_.REACT_APP_API_IP}:${window._env_.REACT_APP_API_PORT}/api/${window._env_.REACT_APP_API_VERSION}/users/login`.replace(' ', ''),
  logout: `${window._env_.REACT_APP_API_PROTOCOL}://${window._env_.REACT_APP_API_IP}:${window._env_.REACT_APP_API_PORT}/api/${window._env_.REACT_APP_API_VERSION}/users/logout`.replace(' ', ''),
  create: `${window._env_.REACT_APP_API_PROTOCOL}://${window._env_.REACT_APP_API_IP}:${window._env_.REACT_APP_API_PORT}/api/${window._env_.REACT_APP_API_VERSION}/users/create`.replace(' ', ''),
  allSharcs: `${window._env_.REACT_APP_API_PROTOCOL}://${window._env_.REACT_APP_API_IP}:${window._env_.REACT_APP_API_PORT}/api/${window._env_.REACT_APP_API_VERSION}/sharcs`.replace(' ', ''),
  singleSharc: `${window._env_.REACT_APP_API_PROTOCOL}://${window._env_.REACT_APP_API_IP}:${window._env_.REACT_APP_API_PORT}/api/${window._env_.REACT_APP_API_VERSION}/sharcs/`.replace(' ', ''),
  commands: `${window._env_.REACT_APP_API_PROTOCOL}://${window._env_.REACT_APP_API_IP}:${window._env_.REACT_APP_API_PORT}/api/${window._env_.REACT_APP_API_VERSION}/sharcs/`.replace(' ', ''),
  sensorFeed: `${window._env_.REACT_APP_API_WSPROTOCOL}://${window._env_.REACT_APP_API_IP}:${window._env_.REACT_APP_API_PORT}/feed/${window._env_.REACT_APP_API_VERSION}/sharcs/`.replace(' ', ''),
  adopt: `${window._env_.REACT_APP_API_PROTOCOL}://${window._env_.REACT_APP_API_IP}:${window._env_.REACT_APP_API_PORT}/api/${window._env_.REACT_APP_API_VERSION}/sharcs/`.replace(' ', ''),
  createMetric: `${window._env_.REACT_APP_API_PROTOCOL}://${window._env_.REACT_APP_API_IP}:${window._env_.REACT_APP_API_PORT}/api/${window._env_.REACT_APP_API_VERSION}/metrics/create`.replace(' ', ''),
  getMetrics: `${window._env_.REACT_APP_API_PROTOCOL}://${window._env_.REACT_APP_API_IP}:${window._env_.REACT_APP_API_PORT}/api/${window._env_.REACT_APP_API_VERSION}/metrics/get`.replace(' ', ''),
  editMetric: `${window._env_.REACT_APP_API_PROTOCOL}://${window._env_.REACT_APP_API_IP}:${window._env_.REACT_APP_API_PORT}/api/${window._env_.REACT_APP_API_VERSION}/metrics/edit`.replace(' ', ''),
  deleteMetric: `${window._env_.REACT_APP_API_PROTOCOL}://${window._env_.REACT_APP_API_IP}:${window._env_.REACT_APP_API_PORT}/api/${window._env_.REACT_APP_API_VERSION}/metrics/delete`.replace(' ', '')
};


let websockets = [];
let availSockets = [];

function App() {
  const navigate = useNavigate()
  const [sharcData, setSharcData] = useState([]);
  const [sharcList, setSharcList] = useState([]);
  const [sharcListFiltered, setSharcListFiltered] = useState([]);
  const [selectedSharc, setSelectedSharc] = useState('')
  const [selectedSensors, setSelectedSensors] = useState({});
  const [sensorData, setSensorData] = useState({});
  const [useDark, setUseDark] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [deviceLoading, setDeviceLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [missingPassword, setMissingPassword] = useState(false);
  const [missingEmail, setMissingEmail] = useState(false);
  const [sharcFilter, setSharcFilter] = useState('all');
  const [adoptableSharcs, setAdoptableSharcs] = useState([]);
  const [userMetrics, setUserMetrics] = useState([]);
  const [userMetricsLength, setUserMetricsLength] = useState(0);
  const [autoAdoptLoading, setAutoAdoptLoading] = useState(false);
  useEffect(() => {
    console.log(endpointsV2)
  }, [])
  useEffect(() => {
    if (loggedIn) {
      let cookie = Cookies.get('authToken')
      hitEndpointsV2('all')
      getMetrics()
    }
  }, [loggedIn])

  useEffect(() => {
    websockets.map((ws) => {
      ws.close()
    })
    websockets = []
    Object.entries(selectedSensors).forEach(([deviceSerial, selectedSensor]) => {
      subscribeToSensor(deviceSerial, selectedSensor);
    });
    userMetrics.map((metric) => {
      let sharc;
      let sensor;
      websockets.map((socket) => {
        sharc = socket.url.split('/')[6]
        sensor = socket.url.split('/')[8]
      })
      if (sharc === metric.sharc && sensor === metric.sensor) {
        console.log('Already Subscribed')
        return;
      } else {
        subscribeToSensor(metric.sharc, metric.sensor)
      }
    })
  }, [selectedSensors, userMetricsLength]);

  useEffect(() => {
    userMetrics.map((metric) => {
      let update = selectedSensors
      update[metric.sharc] = metric.sensor
      setSelectedSensors(update)
    })
  }, [userMetricsLength]);

  useEffect(() => {
    switch (sharcFilter) {
      case 'all':
        setSharcListFiltered(sharcList)
        break;
      case 'avail':
        setSharcListFiltered(sharcList.filter(x => x.avail))
        break;
      case 'unavail':
        setSharcListFiltered(sharcList.filter(x => !x.avail))
        break;
    }
  }, [sharcFilter, sharcList])

  useEffect(() => {
    let cookie = Cookies.get('authToken')
    if (cookie) {
      setLoggedIn(true)
      navigate('/')
    }
  }, [])

  useEffect(() => {
    let cookie = Cookies.get('authToken')
    if (cookie) {
      availSockets.forEach((socket) => {
        socket.close()
      })
      subscribeToAvail(cookie)
    }
  }, [sharcList])

  function handleSensorChange(deviceSerial, selectedSensor) {
    setSelectedSensors((prevSelectedSensors) => ({
      ...prevSelectedSensors,
      [deviceSerial]: selectedSensor,
    }));
  };

  function handleSensorData(deviceSerial, newData) {
    setSensorData((prevSensorData) => ({
      ...prevSensorData,
      [deviceSerial]: newData,
    }));
  };

  function handleMetricsData(deviceSerial, value) {
    if (userMetricsLength !== 0) {
      let contains = false
      userMetrics.map((metric) => {
        if (metric.sharc === deviceSerial) {
          contains = true
        }
      })

      if (contains) {
        let updatedMetrics = userMetrics.map(metric => {
          if (metric.sharc === deviceSerial) {
            if (metric.type === 'chart') {
              metric.data.push({ x: metric.counter += 1, y: value });
              if (metric.data.length > 99) {
                metric.data.shift();
              }
            }
            metric.currentValue = value;
          }
          return metric;
        });
        setUserMetrics(updatedMetrics)
      }

    }
  };

  async function handleLogin() {
    setLoginLoading(true);
    try {
      let res = await axios.post(endpointsV2.login, { email, password }, {
        headers: {
          "Content-Type": 'application/json'
        }
      })
      if (res) {
        Cookies.set('authToken', res.data.token, { expires: 7 })
        setLoggedIn(true)
        setLoginLoading(false)
        navigate('/')
        return true;
      } else {
        setLoginLoading(false);
        return false
      }
    } catch (err) {
      console.log('err', err);
      setLoginLoading(false);
      return false
    }
  }

  async function createMetric(obj) {
    console.log(obj)
    let cookie = Cookies.get('authToken')
    let options = { headers: { "Content-Type": "application/json", 'Authorization': `Token ${cookie}` } }
    if (cookie) {
      try {
        let res = await axios.post(`${endpointsV2.createMetric}`, obj, options)
        return true;
      } catch (err) {
        console.log(err)
        return false;
      }

    }
  }

  async function getMetrics() {
    setUserMetricsLength(0)
    let cookie = Cookies.get('authToken')
    let options = { headers: { 'Authorization': `Token ${cookie}` } }
    if (cookie) {
      try {
        let res = await axios.get(`${endpointsV2.getMetrics}`, options)
        setUserMetrics(res.data.res)
        res.data.res.map((metric) => {
          setUserMetricsLength(userMetricsLength + 1)
        })
        return true;
      } catch (err) {
        return false;
      }
    }
  }

  async function editMetric(body) {
    let cookie = Cookies.get('authToken')
    let options = { headers: { 'Authorization': `Token ${cookie}` } }
    if (cookie) {
      try {
        let res = await axios.post(`${endpointsV2.editMetric}`, body, options)
        return true;
      } catch (err) {
        return false;
      }
    }
  }

  async function deleteMetric(id) {
    let cookie = Cookies.get('authToken')
    let body = { id }
    let options = { headers: { 'Authorization': `Token ${cookie}` } }
    if (cookie) {
      try {
        let res = await axios.post(`${endpointsV2.deleteMetric}`, body, options)
        return true;
      } catch (err) {
        return false;
      }
    }
  }
  async function subscribeToSensor(serial, sensor) {
    let cookie = Cookies.get('authToken')
    const socket = new WebSocket(`${endpointsV2.sensorFeed}${serial}/sensors/${sensor}/live?token=${cookie}`)
    websockets.push(socket)
    socket.addEventListener('open', (message) => {
      console.log(`Sensor Connection Established Device: ${serial} Sensor: ${sensor}`)
    })
    socket.addEventListener('message', (message) => {
      message = JSON.parse(message.data)
      handleSensorData(serial, { [selectedSensors[serial]]: { value: message.value, units: message.units } })
      handleMetricsData(serial, message.value)
    })
    socket.addEventListener('close', (message) => {
      console.log(`Sensor Connection Closed Device: ${serial} Sensor: ${sensor}`)
    })
  }

  async function subscribeToAvail(cookie) {
    sharcList.forEach((sharc) => {
      let socket = new WebSocket(`${endpointsV2.sensorFeed}${sharc.serial}/availability/live?token=${cookie}`)
      availSockets.push(socket)
      socket.addEventListener('open', (message) => {
        console.log(`Availability Connection Established ${sharc.serial}`)
      })
      socket.addEventListener('message', (message) => {
        message = JSON.parse(message.data)
        const updatedSharcList = sharcList.map((sharcUpdate) => {
          if (sharc.serial === sharcUpdate.serial) {
            return { ...sharcUpdate, avail: message.value };
          }
          return sharcUpdate;
        });
        setSharcList(updatedSharcList);
        switch (sharcFilter) {
          case 'all':
            setSharcListFiltered(sharcList)
            break;
          case 'avail':
            setSharcListFiltered(sharcList.filter(x => x.avail))
            break;
          case 'unavail':
            setSharcListFiltered(sharcList.filter(x => !x.avail))
            break;
        }
      })
      socket.addEventListener('close', (message) => {
        console.log(`Availability Connection Closed ${sharc.serial}`)
      })
    })
  }


  async function hitEndpointsV2(end, sharc) {
    if (end === 'all') {
      setListLoading(true)
      try {
        let cookie = Cookies.get('authToken')
        let res = await axios.get(`${endpointsV2.allSharcs}?detail=true`, { headers: { 'Authorization': `Token ${cookie}` } })
        let owned = res.data.owned.map((sharc) => {
          return { serial: sharc.serial, avail: sharc.avail, sensors: sharc.sensors }
        })
        let adoptable = res.data.orphans.map((sharc) => {
          return { serial: sharc.serial, avail: sharc.avail }
        })
        setAdoptableSharcs(adoptable)
        setSharcList(owned)
        setSharcListFiltered(owned)
        setListLoading(false)
      } catch (err) {
        console.log(err)
        if (err.response.status === 401) {
          setLoggedIn(false)
          Cookies.remove('authToken')
          setListLoading(false)
        }
      }
    }
    else if (end === 'single') {
      setDeviceLoading(true)
      try {
        let cookie = Cookies.get('authToken')
        let res = await axios.get(`${endpointsV2.singleSharc}${sharc}?detail=true`, { headers: { 'Authorization': `Token ${cookie}` } })
        setSharcData(res.data)
        console.log(res.data)
        setDeviceLoading(false)
      } catch (err) {
        console.log(err)
        setDeviceLoading(false)
      }
    }
  }

  async function sendActions(action, sharc) {
    let body = {}
    let cookie = Cookies.get('authToken')
    if (action === 'reboot') {
      body = {
        type: 'action',
        payload: {

          device: {
            reset: {
              mqtt: true
            }
          }
        }
      }

    } else if (action === 'bluetooth reboot') {
      body = {
        type: 'action',
        payload: {

          device: {
            reset: {
              ble: true
            }
          }
        }
      }
    }
    try {
      let res = await axios.post(`${endpointsV2.commands}${sharc}/commands?wait=true&timeout=5`, body, { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${cookie}` } })
      return true;
    } catch (err) {
      console.log(err)
      return false;
    }
  }
  async function autoAdoptHelper(serial, key) {
    let cookie = Cookies.get('authToken')
    console.log('Starting Auto Adopt')
    try {
      console.log('Opening Socket')
      let socket = new WebSocket(`${endpointsV2.sensorFeed}${serial}/availability/live?token=${cookie}`)
      socket.addEventListener('open', (message) => {
        console.log(`Availability Connection Established ${serial}, Auto Adopt`)
      })
      socket.addEventListener('message', async (message) => {
        message = JSON.parse(message.data)
        if (message.value === true) {
          console.log('SHARC Connected')
          let adopted = await adoptSharc(serial, key)
          setAutoAdoptLoading(false)
          toast.success('Auto Adoption Successful', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          })
        }
      })
      socket.addEventListener('close', (message) => {
        console.log(`Availability Connection Closed ${serial}, Auto Adopt`)
      })
    } catch (err) {
      console.log(err)
      return false;
    }
  }
  async function sendConfiguration(action, sharc, sensor, string) {
    let body = {}
    let cookie = Cookies.get('authToken')
    if (action === 'units change') {
      body = {
        type: 'configuration',
        payload: {

          sensor: {
            [sensor]: {
              convert: string
            }
          }
        }
      }
    }

    try {
      let res = await axios.post(`${endpointsV2.commands}${sharc}/commands?wait=true&timeout=5`, body, { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${cookie}` } })
      body = {
        type: 'action',
        payload: {

          device: {
            save: {
              mqtt: true
            }
          }
        }
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
      let actionRes = await axios.post(`${endpointsV2.singleSharc}${sharc}/commands?wait=true&timeout=5`, body, { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${cookie}` } })
      return true;
    } catch (err) {
      console.log(err)
      return false;
    }
  }

  async function createAccount(tenantName, user, password) {
    try {
      let body = { tenant: tenantName, email: user, password }
      let options = { headers: { 'Content-Type': 'application/json' } }
      let res = await axios.post(endpointsV2.create, body, options)
      return res
    } catch (err) {
      return err.message
    }
  }

  async function adoptSharc(sharc, adoptionKey) {
    try {
      let body = {
        key: adoptionKey
      };
      let cookie = Cookies.get('authToken')
      let res = await axios.post(`${endpointsV2.adopt}${sharc}/adoptions?wait=true&timeout=5`, body, { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${cookie}` } })
      let getRes = await hitEndpointsV2('all')
      setSelectedSharc('')
      return res;

    } catch (err) {
      console.log(err)
      return false;
    }

  }

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  const lightTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });


  return (
    <ThemeProvider theme={useDark ? darkTheme : lightTheme}>
      <CssBaseline />
      <div id="whole-app">
        <Header useDark={useDark} setUseDark={setUseDark} setLoggedIn={setLoggedIn} loggedIn={loggedIn} endpointsV2={endpointsV2} />
        <Routes >
          <Route path='/' element={
            <AuthenticatedRoute isAuthenticated={loggedIn}>
              <div className='sharc-container'>
                <Navbar />
                <SharcList handleSensorChange={handleSensorChange} sharcList={sharcList} selectedSensors={selectedSensors} setSelectedSharc={setSelectedSharc} selectedSharc={selectedSharc} sensorData={sensorData} sharcFilter={sharcFilter} setSharcFilter={setSharcFilter} sharcListFiltered={sharcListFiltered} hitEndpointsV2={hitEndpointsV2} listLoading={listLoading} />
                <>
                  {selectedSharc && <SharcInfo sharcData={sharcData} sensorData={sensorData} selectedSharc={selectedSharc} selectedSensors={selectedSensors} deviceLoading={deviceLoading} sendActions={sendActions} sendConfiguration={sendConfiguration} endpointsV2={endpointsV2} hitEndpointsV2={hitEndpointsV2} setSelectedSharc={setSelectedSharc} />}
                </>
              </div >
            </AuthenticatedRoute>} />
          <Route path='/livechart' element={
            <AuthenticatedRoute isAuthenticated={loggedIn}>
              <div className='sharc-container'>
                <Navbar />
                <SharcList handleSensorChange={handleSensorChange} sharcList={sharcList} selectedSensors={selectedSensors} setSelectedSharc={setSelectedSharc} selectedSharc={selectedSharc} sensorData={sensorData} sharcFilter={sharcFilter} setSharcFilter={setSharcFilter} sharcListFiltered={sharcListFiltered} listLoading={listLoading} hitEndpointsV2={hitEndpointsV2} />
                {selectedSharc && <LiveChart sensorData={sensorData} selectedSharc={selectedSharc} selectedSensors={selectedSensors} />}
              </div>
            </AuthenticatedRoute>
          } isAuthenticated={loggedIn} />
          <Route path='/adopt' element={<AuthenticatedRoute isAuthenticated={loggedIn}>
            <div className='sharc-container'>
              <Navbar />
              <AdoptList setSelectedSharc={setSelectedSharc} selectedSharc={selectedSharc} listLoading={listLoading} hitEndpointsV2={hitEndpointsV2} adoptableSharcs={adoptableSharcs} adoptSharc={adoptSharc} />
              {/* {selectedSharc && <Adopt adoptableSharcs={adoptableSharcs} sharcData={sharcData} selectedSharc={selectedSharc} adoptSharc={adoptSharc} />} */}
            </div>
          </AuthenticatedRoute>} />
          <Route path='/metrics' element={<AuthenticatedRoute isAuthenticated={loggedIn}>
            <div className='sharc-container'>
              <Navbar />
              <Metrics sharcList={sharcList} userMetrics={userMetrics} setUserMetrics={setUserMetrics} userMetricsLength={userMetricsLength} setUserMetricsLength={setUserMetricsLength} createMetric={createMetric} getMetrics={getMetrics} editMetric={editMetric} deleteMetric={deleteMetric} />
            </div>
          </AuthenticatedRoute>} />
          <Route path='/auto-adopt' element={<AuthenticatedRoute isAuthenticated={loggedIn}>
            <div className='sharc-container'>
              <Navbar />
              <AutoAdopt autoAdoptHelper={autoAdoptHelper} autoAdoptLoading={autoAdoptLoading} setAutoAdoptLoading={setAutoAdoptLoading} />
            </div>
          </AuthenticatedRoute>} />
          <Route path='/login' element={< Login setLoggedIn={setLoggedIn} email={email} password={password} setEmail={setEmail} setPassword={setPassword} handleLogin={handleLogin} loginError={loginError} setLoginError={setLoginError} missingPassword={missingPassword} setMissingPassword={setMissingPassword} missingEmail={missingEmail} setMissingEmail={setMissingEmail} />} />
          <Route path='/account-creation' element={< AccountCreation createAccount={createAccount} />} />
        </Routes>
        {loginLoading && <div className='loading-container'><CircularProgress size={50} /></div>}
        <Footer useDark={useDark} />
      </div>
    </ThemeProvider >
  );
};


export default App;
