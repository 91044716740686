import React, { useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Paper, Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import './SharcList.css'
import RedDot from '../../assets/red.png'
import GreenDot from '../../assets/green.png'
import CircularProgress from '@mui/material/CircularProgress';

export default function SharcList({ sharcList, selectedSensors, handleSensorChange, setSelectedSharc, hitEndpoints, sensorData, selectedSharc, sharcFilter, setSharcFilter, sharcListFiltered, hitEndpointsV2, listLoading }) {

    useEffect(() => {
        setSelectedSharc('')
        console.log(sharcListFiltered)
    }, [])

    return !listLoading ? (
        <>
            {sharcListFiltered.length > 0 ? < Paper className='sharc-list' elevation={5}>
                <Box sx={{ padding: 1 }}>
                    <Select value={sharcFilter} onChange={(e) => setSharcFilter(e.target.value)}>
                        <MenuItem key={'all'} value={'all'}>Show All</MenuItem>
                        <MenuItem key={'avail'} value={'avail'}>Show Available</MenuItem>
                        <MenuItem key={'unavail'} value={'unavail'}>Show Unavailable</MenuItem>
                    </Select>
                </Box>
                <List className='sharc-list-mui' style={{ maxHeight: '87%', overflow: 'auto' }}>
                    {sharcListFiltered.sort((x, y) => Number(y.avail) - Number(x.avail)).map((sharc) => (
                        <ListItem key={sharc.serial}>
                            <ListItemButton onClick={(e) => {
                                if (selectedSharc !== sharc.serial) {

                                    setSelectedSharc(sharc.serial)
                                    hitEndpointsV2('single', sharc.serial)
                                }
                            }} selected={sharc.serial === selectedSharc}>
                                <div className='list-item-button-internal'>
                                    <div className='list-item-serial'><img id='avail-dot' src={sharc.avail ? GreenDot : RedDot} />{sharc.serial}</div>
                                    <Box className='list-item-sensor'>
                                        <ListItemText className='list-item-sensor'>
                                            <Select label='Sensor' value={selectedSensors[sharc.serial] || ''} onChange={(e) => {
                                                handleSensorChange(sharc.serial, e.target.value)
                                            }} className='sensor-select' renderValue={(e) => e} style={{maxWidth: 65}}>
                                                {sharc.sensors.map((sensor) => (
                                                    <MenuItem key={sensor} value={sensor}>
                                                        <Tooltip title={sensor} disableInteractive>
                                                            <div>{sensor}</div>
                                                        </Tooltip>
                                                    </MenuItem>
                                                ))}
                                                {/* {[{ sensor: 's0', image: ' https://assets.rs-online.com/f_auto,q_auto,c_scale,w_400/71242287.jpg' }, { sensor: 's1', image: 'https://www.milspecwiring.com/cdn-cgi/image/quality%3D85/assets/images/IRN3.jpg' }, { sensor: 's2', image: 'https://m.media-amazon.com/images/I/71mN-1PgKJL._SX522_.jpg' }, { sensor: 's3', image: 'https://static.grainger.com/rp/s/is/image/Grainger/5DEK0_AS01?$adapimg$&hei=536&wid=536' }].map((sensor) => (
                                                    <MenuItem key={sensor.sensor} value={sensor.sensor}>
                                                        <Tooltip title={sensor.sensor} disableInteractive>
                                                            <img src={sensor.image} className='sensor-image'></img>
                                                        </Tooltip>
                                                    </MenuItem>
                                                ))} */}
                                            </Select>
                                        </ListItemText>
                                    </Box>
                                    {(sensorData[sharc.serial] && sensorData[sharc.serial][selectedSensors[sharc.serial]]) &&
                                        <ListItemText>
                                            <div className='sensor-data-in-list'>
                                                {`${sensorData[sharc.serial][selectedSensors[sharc.serial]].value} ${(['count', '/'].includes(sensorData[sharc.serial][selectedSensors[sharc.serial]].units)) ? '' : sensorData[sharc.serial][selectedSensors[sharc.serial]].units}`}
                                            </div>
                                        </ListItemText>}
                                    {((!sensorData[sharc.serial] && selectedSensors[sharc.serial]) || (sensorData[sharc.serial] && !sensorData[sharc.serial][selectedSensors[sharc.serial]])) && <ListItemText>
                                        <div className='sensor-data-in-list'>
                                            No Data Available
                                        </div>
                                    </ListItemText>}
                                    {(!selectedSensors[sharc.serial]) && <ListItemText>
                                        <div className='sensor-data-in-list'>
                                            {sharc.avail ? 'No Sensor Selected' : 'Sharc Offline'}
                                        </div>
                                    </ListItemText>}
                                </div>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Paper > : <Paper sx={{ width: '100%' }} elevation={5}>
                <div className='sharc-text'>No Sharcs Connected </div>
            </Paper>}
        </>
    ) : <Paper className='sharc-list' elevation={5} sx={{ width: '20vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress size={50}></CircularProgress></Paper>
}