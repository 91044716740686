import { Button, Paper, Modal, TextField, Select, MenuItem, List, ListItem, ListItemText } from "@mui/material"
import { useState, useEffect } from 'react'
import './Metrics.css'
import { LineChart } from '@mui/x-charts/LineChart';
import { create } from "@mui/material/styles/createTransitions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GridLayout from "react-grid-layout";

import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'

export default function Metrics({ userMetrics, setUserMetrics, sharcList, userMetricsLength, setUserMetricsLength, createMetric, getMetrics, editMetric, deleteMetric }) {
    const [openAddMetric, setOpenAddMetric] = useState(false);
    const [openEditMetric, setOpenEditMetric] = useState(false);
    const [openDeleteMetric, setOpenDeleteMetric] = useState(false);
    const [metricId, setMetricId] = useState('');
    const [metricName, setMetricName] = useState('');
    const [metricSharc, setMetricSharc] = useState('');
    const [metricSensor, setMetricSensor] = useState('');
    const [metricUnits, setMetricUnits] = useState('');
    const [metricType, setMetricType] = useState('');
    const [sharcSensors, setSharcSensors] = useState(['s0', 's1', 's2', 's3'])
    const [missingInfo, setMissingInfo] = useState(false);

    useEffect(() => {
        if (sharcList && metricSharc) {

            setSharcSensors(sharcList.find(x => x.serial === metricSharc).sensors)
        }
    }, [metricSharc])

    function handleEditMetric(id) {
        setOpenEditMetric(true)
        let metric = userMetrics.find(x => x.id === id)
        setMetricId(metric.id)
        setMetricName(metric.name)
        setMetricSharc(metric.sharc)
        setMetricSensor(metric.sensor)
        setMetricUnits(metric.units)
        setMetricType(metric.type)
    }
    function handleDeleteMetric(id) {
        setOpenDeleteMetric(true)
        let metric = userMetrics.find(x => x.id === id)
        setMetricId(metric.id)
        setMetricName(metric.name)
        setMetricSharc(metric.sharc)
        setMetricSensor(metric.sensor)
        setMetricUnits(metric.units)
        setMetricType(metric.type)
    }
    return (
        <>
            <ToastContainer className='toast' />
            <Paper sx={{ width: '100%' }} elevation={5} id='metric-paper'>
                <Modal open={openAddMetric} onClose={() => setOpenAddMetric(false)}>
                    <Paper className='modal-inside-paper-metric'>
                        <div className="modal-inside-container">
                            <div className="modal-title">Create New Metric</div>
                            <form onSubmit={async (e) => {
                                // createMetric()
                                if (metricName && metricSensor && metricSharc && metricType && metricUnits) {
                                    console.log(`Sharc: '${metricSharc}', Parse: '${metricSensor}', Units: '${metricUnits}', Type: '${metricType}'`)

                                    let res = await createMetric({ name: metricName, units: metricUnits, type: metricType, sensor: metricSensor, sharc: metricSharc, data: [], currentValue: null, counter: 0 })
                                    if (res) {
                                        toast.success('Metric Added Successfully', {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "dark",
                                        })
                                        let getNew = await getMetrics()
                                        if (getNew) {
                                            setMetricName('')
                                            setMetricSharc('')
                                            setMetricSensor('')
                                            setMetricUnits('')
                                            setMetricType('')
                                        } else {
                                            toast.error('Couldn\'t Get Metrics', {
                                                position: "top-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "dark",
                                            })
                                        }
                                    } else {
                                        toast.error('Couldn\'t Save Metric', {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "dark",
                                        })
                                    }
                                } else {
                                    setMissingInfo(true)
                                }
                                setOpenAddMetric(false)
                                console.log('Created')
                            }} className="modal-form">
                                <div className="select-container">
                                    <div className="select-text">Metric Name: </div>
                                    <TextField label='Name' value={metricName} onChange={(e) => {
                                        e.preventDefault()
                                        setMetricName(e.target.value)
                                    }}></TextField>
                                </div>
                                <div className="select-container">
                                    <div className="select-text">Select Your Sharc: </div>
                                    <Select label='Your Sharcs' value={metricSharc} onChange={(e) => {
                                        setMetricSharc(e.target.value)
                                    }}>
                                        {sharcList.map((sharc) => {
                                            return (
                                                <MenuItem value={sharc.serial} key={sharc.serial}>{sharc.serial}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <div className="select-container">
                                    <div className="select-text">Select Your Sensor: </div>
                                    <Select label='Your Sharcs' value={metricSensor} onChange={(e) => {
                                        setMetricSensor(e.target.value)
                                    }}>
                                        {sharcSensors.map((sensor) => {
                                            return (
                                                <MenuItem value={sensor} key={sensor}>{sensor}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <div className="select-container">
                                    <div className="select-text">Display Units: </div>
                                    <TextField label='Units' value={metricUnits} onChange={(e) => {
                                        e.preventDefault()
                                        setMetricUnits(e.target.value)
                                    }}></TextField>
                                </div>
                                <div className="select-container">
                                    <div className="select-text">Metric Type: </div>
                                    <Select label='Your Sharcs' value={metricType} onChange={(e) => {
                                        setMetricType(e.target.value)
                                    }}>
                                        {['text', 'chart'].map((type) => {
                                            return (
                                                <MenuItem value={type} key={type}>{type}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <div className='button-container'>
                                    <Button variant="contained" onClick={(e) => {
                                        e.preventDefault()
                                        setOpenAddMetric(false)
                                        setMissingInfo(false)
                                        setMetricId('')
                                        setMetricName('')
                                        setMetricSharc('')
                                        setMetricSensor('')
                                        setMetricUnits('')
                                        setMetricType('')
                                    }}>Cancel</Button>
                                    <Button variant="contained" onClick={async (e) => {
                                        e.preventDefault()
                                        if (metricName && metricSensor && metricSharc && metricType && metricUnits) {
                                            console.log(`Sharc: '${metricSharc}', Parse: '${metricSensor}', Units: '${metricUnits}', Type: '${metricType}'`)
                                            let res = await createMetric({ name: metricName, units: metricUnits, type: metricType, sensor: metricSensor, sharc: metricSharc, data: [], currentValue: null, counter: 0 })
                                            if (res) {
                                                toast.success('Metric Added Successfully', {
                                                    position: "top-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                    theme: "dark",
                                                })
                                                let getNew = await getMetrics()
                                                setMetricId('')
                                                setMetricName('')
                                                setMetricSharc('')
                                                setMetricSensor('')
                                                setMetricUnits('')
                                                setMetricType('')
                                            } else {
                                                toast.error('API Error', {
                                                    position: "top-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                    theme: "dark",
                                                })
                                            }
                                        } else {
                                            setMissingInfo(true)
                                        }
                                        setOpenAddMetric(false)
                                        console.log('Created')
                                    }}>Save</Button>
                                </div>
                            </form>
                        </div>
                    </Paper>
                </Modal>
                <Modal open={openEditMetric} onClose={() => setOpenEditMetric(false)}>
                    <Paper className='modal-inside-paper-metric'>
                        <div className="modal-inside-container">
                            <div className="modal-title">Edit Metric</div>
                            <form onSubmit={async (e) => {
                                if (metricName && metricSensor && metricSharc && metricType && metricUnits) {
                                    let res = await editMetric({ id: metricId, name: metricName, units: metricUnits, type: metricType, sensor: metricSensor, sharc: metricSharc, data: [], currentValue: null, counter: 0 })
                                    if (res) {
                                        toast.success('Metric Edited Successfully', {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "dark",
                                        })
                                        let getNew = await getMetrics()
                                        setMetricId('')
                                        setMetricName('')
                                        setMetricSharc('')
                                        setMetricSensor('')
                                        setMetricUnits('')
                                        setMetricType('')
                                    } else {
                                        toast.error('API Error', {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "dark",
                                        })
                                    }
                                    console.log('Edited')
                                } else {
                                    setMissingInfo(true)
                                }
                                setOpenEditMetric(false)
                                console.log('Edited')
                            }} className="modal-form">
                                <div className="select-container">
                                    <div className="select-text">Metric Name: </div>
                                    <TextField label='Name' value={metricName} onChange={(e) => {
                                        e.preventDefault()
                                        setMetricName(e.target.value)
                                    }}></TextField>
                                </div>
                                <div className="select-container">
                                    <div className="select-text">Select Your Sharc: </div>
                                    <Select label='Your Sharcs' value={metricSharc} onChange={(e) => {
                                        setMetricSharc(e.target.value)
                                    }}>
                                        {sharcList.map((sharc) => {
                                            return (
                                                <MenuItem value={sharc.serial} key={sharc.serial}>{sharc.serial}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <div className="select-container">
                                    <div className="select-text">Select Your Sensor: </div>
                                    <Select label='Your Sharcs' value={metricSensor} onChange={(e) => {
                                        setMetricSensor(e.target.value)
                                    }}>
                                        {['s0', 's1', 's2', 's3'].map((sensor) => {
                                            return (
                                                <MenuItem value={sensor} key={sensor}>{sensor}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <div className="select-container">
                                    <div className="select-text">Display Units: </div>
                                    <TextField label='Units' value={metricUnits} onChange={(e) => {
                                        e.preventDefault()
                                        setMetricUnits(e.target.value)
                                    }}></TextField>
                                </div>
                                <div className="select-container">
                                    <div className="select-text">Metric Type: </div>
                                    <Select label='Your Sharcs' value={metricType} onChange={(e) => {
                                        setMetricType(e.target.value)
                                    }}>
                                        {['text', 'chart'].map((type) => {
                                            return (
                                                <MenuItem value={type} key={type}>{type}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <div className='button-container'>
                                    <Button variant="contained" onClick={(e) => {
                                        e.preventDefault()
                                        setOpenEditMetric(false)
                                        setMissingInfo(false)
                                        setMetricId('')
                                        setMetricName('')
                                        setMetricSharc('')
                                        setMetricSensor('')
                                        setMetricUnits('')
                                        setMetricType('')
                                    }}>Cancel</Button>
                                    <Button variant="contained" onClick={async (e) => {
                                        e.preventDefault()
                                        if (metricName && metricSensor && metricSharc && metricType && metricUnits) {
                                            let res = await editMetric({ id: metricId, name: metricName, units: metricUnits, type: metricType, sensor: metricSensor, sharc: metricSharc, data: [], currentValue: null, counter: 0 })
                                            if (res) {
                                                toast.success('Metric Edited Successfully', {
                                                    position: "top-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                    theme: "dark",
                                                })
                                                let getNew = await getMetrics()
                                                setMetricId('')
                                                setMetricName('')
                                                setMetricSharc('')
                                                setMetricSensor('')
                                                setMetricUnits('')
                                                setMetricType('')
                                            } else {
                                                toast.error('API Error', {
                                                    position: "top-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                    theme: "dark",
                                                })
                                            }
                                            console.log('Edited')
                                        } else {
                                            setMissingInfo(true)
                                        }
                                        setOpenEditMetric(false)
                                        console.log('Edited')
                                    }}>Save</Button>
                                </div>
                            </form>
                        </div>
                    </Paper>
                </Modal>
                <Modal open={openDeleteMetric} onClose={() => setOpenDeleteMetric(false)}>
                    <Paper className='modal-inside-paper-metric'>
                        <div className="modal-inside-container">
                            <div className="modal-title">Delete Metric</div>
                            <div className="modal-form">
                                <div>Are you sure you want to delete metric {metricName}</div>
                                <div className='button-container'>
                                    <Button variant="contained" onClick={(e) => {
                                        e.preventDefault()
                                        setOpenDeleteMetric(false)
                                    }}>Cancel</Button>
                                    <Button variant="contained" onClick={async (e) => {
                                        e.preventDefault()
                                        let res = await deleteMetric(metricId)
                                        if (res) {
                                            toast.success('Metric Deleted Successfully', {
                                                position: "top-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "dark",
                                            })
                                            let getNew = await getMetrics()
                                            setMetricId('')
                                            setMetricName('')
                                            setMetricSharc('')
                                            setMetricSensor('')
                                            setMetricUnits('')
                                            setMetricType('')
                                            setOpenDeleteMetric(false)
                                        } else {
                                            toast.error('API Error', {
                                                position: "top-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "dark",
                                            })
                                        }
                                        console.log('Deleted')
                                    }}>Yes</Button>
                                </div>
                            </div>
                        </div>
                    </Paper>
                </Modal>
                <div className="metric-container">
                    <Button className="metric-button" onClick={(e) => {
                        setOpenAddMetric(true)
                    }} variant="contained">Add</ Button>
                </div>
                {userMetrics.length > 0 &&
                    <div className="metric-list-container">
                        {/* <GridLayout
                            className="layout"
                            cols={12}
                            rowHeight={30}
                            width={'100%'}
                        > */}


                        {userMetrics.map((metric, index) => {
                            return metric.type === 'chart' ? (
                                <Paper elevation={10} key={index}

                                >
                                    <div className="metric-title-container">
                                        <div className="metric-options">
                                            <Button disabled={true} sx={{ opacity: 0 }}>Edit</Button>
                                            <Button disabled={true} sx={{ opacity: 0 }}>Delete</Button>
                                        </div>
                                        <div className="metric-title-text">{metric.name}</div>
                                        <div className="metric-options">
                                            <Button onClick={(e) => {
                                                handleEditMetric(metric.id)
                                            }}>Edit</Button>
                                            <Button onClick={(e) => {
                                                handleDeleteMetric(metric.id)
                                            }}>Delete</Button>
                                        </div>
                                    </div>
                                    {metric.data.length > 0 ? <div className="metric-item-container">
                                        <div className="metric-info-container">
                                            <div>Value: {metric.currentValue}</div>
                                            <div>Units: {metric.units}</div>
                                        </div>
                                        <div className="metric-chart-container">
                                            < LineChart
                                                xAxis={[{
                                                    dataKey: 'x',
                                                    showMark: false
                                                }]}
                                                series={[
                                                    {
                                                        dataKey: 'y',
                                                        showMark: false,
                                                        curve: ['s0', 's1'].includes(metric.sensor) ? 'step' : 'natural'
                                                    },
                                                ]}
                                                dataset={metric.data}
                                                disableLineItemHighlight={true}
                                            />
                                        </div>
                                    </div> : <div className="metric-item-container" key={index} >
                                        <div className="metric-text-container">
                                            <div>Waiting for Data...</div>
                                        </div>
                                    </div>}
                                </Paper>
                            ) : (
                                <Paper elevation={10} key={index}>
                                    <div className="metric-title-container">
                                        <div className="metric-options">
                                            <Button disabled={true} sx={{ opacity: 0 }}>Edit</Button>
                                            <Button disabled={true} sx={{ opacity: 0 }}>Delete</Button>
                                        </div>
                                        <div className="metric-title-text">{metric.name}</div>
                                        <div className="metric-options">
                                            <Button onClick={(e) => {
                                                handleEditMetric(metric.id)
                                            }}>Edit</Button>
                                            <Button onClick={(e) => {
                                                handleDeleteMetric(metric.id)
                                            }}>Delete</Button>
                                        </div>
                                    </div>
                                    {metric.currentValue ? <div className="metric-item-container" >
                                        <div className="metric-text-container">
                                            <div>{metric.currentValue} {metric.units}</div>
                                        </div>
                                    </div> : <div className="metric-item-container" >
                                        <div className="metric-text-container">
                                            <div>Waiting for Data...</div>
                                        </div>
                                    </div>}
                                </Paper>
                            )
                        })}
                        {/* </GridLayout> */}
                    </div>
                }
            </Paper>
        </>
    )
}