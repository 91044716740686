import { Button, Paper, CircularProgress } from "@mui/material";
import './Setup.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const deviceInfo = '00000000-0001-1337-1337-000000000005'
const actions = '00000000-0002-1337-1337-000000000005'

const adoptionKeyUUID = '0000002D-0001-1337-1337-000000000005'.toLowerCase()
const serialUUID = '00000003-0001-1337-1337-000000000005'
const rebootMQTT = '00000004-0002-1337-1337-000000000005'

const options = {
    filters: [
        { namePrefix: 'SHARC-' }
    ],
    optionalServices: [deviceInfo, actions]
}
const decoder = new TextDecoder('utf-8');
let utf8Encode = new TextEncoder();

export default function AutoAdopt({ autoAdoptHelper, autoAdoptLoading, setAutoAdoptLoading }) {

    return !autoAdoptLoading ? (
        <>
            <ToastContainer className='toast' />
            <Paper id="setup-page">
                <div className="setup-container">
                    <div className="setup-container-title">Auto Adopt</div>
                    <div className="setup-container-body">
                        <div className="setup-help-text">Please ensure your SHARC is in Bluetooth mode</div>
                        <div className="setup-help-text">The SHARC should have a cyan light emitting from it</div>
                        <div className="setup-buttons-container">
                            <Button onClick={async (e) => {
                                e.preventDefault()
                                try {
                                    setAutoAdoptLoading(true)
                                    let device = await navigator.bluetooth.requestDevice(options)
                                    device.addEventListener('gattserverdisconnected', console.log('disconnected'));
                                    let deviceConnect = await device.gatt.connect()
                                    let deviceService = await deviceConnect.getPrimaryService(deviceInfo)
                                    let adoptionKeyChar = await deviceService.getCharacteristic(adoptionKeyUUID)
                                    let adoptionKeyVal = await adoptionKeyChar.readValue()
                                    let adoptionKey = decoder.decode(adoptionKeyVal)
                                    let sharcSerialChar = await deviceService.getCharacteristic(serialUUID)
                                    let sharcSerialVal = await sharcSerialChar.readValue()
                                    let sharcSerial = decoder.decode(sharcSerialVal)
                                    let reboot = await deviceConnect.getPrimaryService(actions)
                                    let rebootChar = await reboot.getCharacteristic(rebootMQTT)
                                    let rebootValue = utf8Encode.encode(1)
                                    let writeChar = await rebootChar.writeValue(rebootValue)
                                    try {
                                        let res = await autoAdoptHelper(sharcSerial, adoptionKey)
                                    } catch (err) {
                                        console.log(err)
                                        setAutoAdoptLoading(false)

                                        toast.error('Auto Adoption Failed', {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "dark",
                                        })
                                    }
                                } catch (err) {
                                    setAutoAdoptLoading(false)
                                    console.log(err)
                                }
                            }} variant="contained">Search for SHARC</Button>
                        </div>
                    </div>
                </div>
            </Paper>
        </>
    ) : <Paper className='sharc-list' elevation={5} sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress size={50}></CircularProgress></Paper>
}