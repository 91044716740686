import { Button, Paper, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Cookies from 'js-cookie';

import 'react-toastify/dist/ReactToastify.css';
import './SharcInfo.css'


export default function SharcInfo({ sharcData, sensorData, selectedSharc, selectedSensors, deviceLoading, sendActions, sendConfiguration, adoptSharc, endpointsV2, hitEndpointsV2, setSelectedSharc }) {
    const [openReboot, setOpenReboot] = useState(false);
    const [openBluetoothReboot, setOpenBluetoothReboot] = useState(false);
    const [openRelease, setOpenRelease] = useState(false);
    const [openUnits, setOpenUnits] = useState(false);
    const [defaultUnitsString, setDefaultUnitsString] = useState('');
    const [unitsString, setUnitsString] = useState('');
    const [currentFormula, setCurrentFormula] = useState('');
    const [commandLoading, setCommandLoading] = useState(false);

    useEffect(() => {
        if ((sensorData[selectedSharc] && sensorData[selectedSharc][selectedSensors[selectedSharc]] && unitsString === '')) {
            setUnitsString((sensorData[selectedSharc][selectedSensors[selectedSharc]].units))
            setDefaultUnitsString((sensorData[selectedSharc][selectedSensors[selectedSharc]].units))
        }
    }, [sensorData])


    useEffect(() => {
        if (openUnits) {
            setCurrentFormula(sharcData?.sensor[selectedSensors[selectedSharc]].convert)
        } else {
            setCurrentFormula('')
        }
    }, [openUnits])

    useEffect(() => {
        setDefaultUnitsString('')
        setUnitsString('')
        setCurrentFormula('')
    }, [selectedSharc])

    function handleClose() {
        setOpenReboot(false)
    }
    function handleBluetoothClose() {
        setOpenBluetoothReboot(false)
    }
    function handleUnitsClose() {
        setUnitsString(defaultUnitsString)
        setOpenUnits(false)
    }

    async function handleSendingActions(action, dev) {
        if (action === 'reboot') {
            setCommandLoading(true)
            let res = await sendActions(action, dev)
            if (res) {
                toast.success('Rebooting...', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })
                setOpenReboot(false)
            } else {
                toast.error('API Error', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })
                setOpenReboot(false)
            }
            setCommandLoading(false)
        } else if (action === 'bluetooth reboot') {
            setCommandLoading(true)
            let res = await sendActions(action, dev)
            if (res) {
                toast.success('Rebooting into Bluetooth...', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })
                setOpenBluetoothReboot(false)
            } else {
                toast.error('API Error', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })
                setOpenBluetoothReboot(false)
            }
            setCommandLoading(false)
        }

    }
    async function handleSendingUnits() {
        commandLoading(true)
        let formulaUnit = currentFormula.split(',')[1].trim().replaceAll("'", '')
        let formulaReplace = currentFormula.replace(formulaUnit, unitsString)
        let res = await sendConfiguration('units change', selectedSharc, [selectedSensors[selectedSharc]], formulaReplace)
        if (res) {
            toast.success('Units Changed, Rebooting Device ...', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
            setOpenUnits(false)
        } else {
            toast.error('API Error', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
            setOpenUnits(false)
        }
        commandLoading(false)
    }

    async function handleRelease() {
        setCommandLoading(true)
        try {
            let cookie = Cookies.get('authToken')
            let res = await axios.delete(`${endpointsV2.adopt}${selectedSharc}/adoptions?wait=true&timeout=5`, { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${cookie}` } })
            toast.success('SHARC Released', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
            setSelectedSharc('')
            let getRes = await hitEndpointsV2('all')
            setCommandLoading(false)
            setOpenRelease(false)
            return res;
        } catch (err) {
            console.log(err)
            toast.error('API Error', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
            setCommandLoading(false)
            setOpenRelease(false)

            return false;
        }
    }

    return !deviceLoading ? (

        <div>
            <ToastContainer className='toast' />
            <Paper className='sharc-info' elevation={5}>
                <Modal open={openReboot} onClose={handleClose}>
                    <Paper className='modal-inside-paper'>
                        {!commandLoading ?
                            <>
                                <div className='modal-text'>Are you sure you want to reboot?</div>
                                <div className='button-form'>
                                    <Button variant='contained' onClick={handleClose}>No</Button>
                                    <Button variant='contained' onClick={async () => {
                                        handleSendingActions('reboot', selectedSharc)
                                    }}>Yes</Button>
                                </div>
                            </> : <Paper className='sharc-list' elevation={5} sx={{ width: '20vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress size={50}></CircularProgress></Paper>}
                    </Paper>
                </Modal>
                <Modal open={openBluetoothReboot} onClose={handleBluetoothClose}>
                    <Paper className='modal-inside-paper'>
                        {!commandLoading ?
                            <>
                                <div className='modal-text'>Are you sure you want to reboot into bluetooth?</div>
                                <div className='button-form'>
                                    <Button variant='contained' onClick={handleBluetoothClose}>No</Button>
                                    <Button variant='contained' onClick={() => handleSendingActions('bluetooth reboot', selectedSharc)}>Yes</Button>
                                </div>
                            </> : <Paper className='sharc-list' elevation={5} sx={{ width: '20vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress size={50}></CircularProgress></Paper>}
                    </Paper>
                </Modal>
                <Modal open={openUnits} onClose={handleUnitsClose}>
                    <Paper className='modal-inside-paper'>
                        {!commandLoading ? <div className='modal-inside-container'>
                            <div className='modal-text'>Changing units WILL reboot the SHARC</div>
                            <div className='units-text'>
                                <TextField helperText='Enter Units' value={unitsString} onChange={(e) => {
                                    setUnitsString(e.target.value)
                                }}></TextField>
                            </div>
                            <div className='button-form'>
                                <Button variant='contained' onClick={handleUnitsClose}>Cancel</Button>
                                <Button variant='contained' onClick={() => handleSendingUnits()}>Save</Button>
                            </div>
                        </div> : <Paper className='sharc-list' elevation={5} sx={{ width: '20vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress size={50}></CircularProgress></Paper>}
                    </Paper>
                </Modal>
                <Modal open={openRelease} onClose={() => setOpenRelease(false)}>
                    <Paper className='modal-inside-paper'>
                        {!commandLoading ? <div className='modal-inside-container'>
                            <div className='modal-text'>Are you sure you want to release this SHARC?</div>
                            <div className='button-form'>
                                <Button variant='contained' onClick={() => setOpenRelease(false)}>Cancel</Button>
                                <Button variant='contained' onClick={() => handleRelease()}>Yes</Button>
                            </div>
                        </div> : <Paper className='sharc-list' elevation={5} sx={{ width: '20vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress size={50}></CircularProgress></Paper>}
                    </Paper>
                </Modal>
                <div className='sharc-info-container'>
                    <div className='sharc-information'>ID: {sharcData?.serial}</div>
                    <div className='sharc-information'>Status: {sharcData?.avail ? 'Online' : 'Offline'}</div>
                    <div className='sharc-information'>IP Address: {sharcData?.net?.ip}</div>
                    {(sensorData[selectedSharc] && sensorData[selectedSharc][selectedSensors[selectedSharc]]) && <div className='sharc-information'>Current Value: {sensorData[selectedSharc][selectedSensors[selectedSharc]]?.value} {(sensorData[selectedSharc][selectedSensors[selectedSharc]]?.units === 'count' || sensorData[selectedSharc][selectedSensors[selectedSharc]]?.units === '/') ? '' : sensorData[selectedSharc][selectedSensors[selectedSharc]]?.units}</div>}
                    {(!sensorData[selectedSharc] || (sensorData[selectedSharc] && !sensorData[selectedSharc][selectedSensors[selectedSharc]])) && <div className='sharc-information'>No Sensor Data Available</div>}
                    <div className='button-container'>
                        {['s2', 's3'].includes(selectedSensors[selectedSharc]) && <Button variant='contained' onClick={(e) => {
                            e.preventDefault()
                            setOpenUnits(true)
                        }} className='reboot-buttons'>Change Units</Button>}
                        <Button variant='contained' onClick={(e) => {
                            e.preventDefault()
                            setOpenReboot(true)
                        }} className='reboot-buttons'>Reboot</Button>
                        <Button variant='contained' onClick={(e) => {
                            e.preventDefault()
                            setOpenBluetoothReboot(true)
                        }} className='reboot-buttons'>Reboot (BLE)</Button>
                        <Button variant='contained' onClick={(e) => {
                            e.preventDefault()
                            setOpenRelease(true)
                        }} className='reboot-buttons'>Release Sharc</Button>
                    </div>
                </div>
            </Paper>
        </div>
    ) : <Paper className='sharc-info' elevation={5}>
        <div className='sharc-info-container' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column' }}>
            <div style={{ opacity: 0 }}>No Sensor Data Available</div>
            <CircularProgress size={50} />
            <div style={{ opacity: 0 }}>No Sensor Data Available</div>

        </div>
    </Paper>
}
