import { useState, useEffect } from 'react';
import { Button, Paper, TextField, Modal, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './AccountCreation.css'

export default function AccountCreation({ createAccount }) {
    const navigate = useNavigate()
    const [tenant, setTenant] = useState('')
    const [user, setUser] = useState('')
    const [pass, setPass] = useState('')
    const [createLoading, setCreateLoading] = useState(false)
    const [error, setError] = useState(false)
    return !createLoading ? (
        <>
            <ToastContainer className='toast' />
            <Paper id='account-creation'>
                <div className='account-creation-container'>
                    <form className='account-creation-form' onSubmit={async (e) => {
                        // e.preventDefault()
                        if (tenant && user && pass) {
                            setError(false)
                            setCreateLoading(true)
                            let res = await createAccount(tenant, user, pass)
                            if (res) {
                                setTenant('')
                                setUser('')
                                setPass('')
                                setCreateLoading(false)
                                toast.success(`Account Creation Successful`, {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "dark",
                                });
                                navigate('/login')
                            } else {
                                setCreateLoading(false)
                                toast.error(`Account Creation Failed`, {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "dark",
                                });
                            }
                        } else {
                            setError(true)
                        }
                    }}>
                        <div className='login-text'>Create Account</div>
                        <TextField label='Business Name' value={tenant} onChange={(e) => {
                            setTenant(e.target.value)
                        }}></TextField>
                        <TextField label='Email' value={user} onChange={(e) => {
                            setUser(e.target.value)
                        }}></TextField>
                        <TextField label='Password' value={pass} onChange={(e) => {
                            setPass(e.target.value)
                        }} type='password'></TextField>

                        <div className='button-container-create'>
                            <Button variant='contained' onClick={() => {
                                setTenant('')
                                setUser('')
                                setPass('')
                                navigate('/login')
                            }}>Cancel</Button>
                            <Button variant='contained' onClick={async (e) => {
                                e.preventDefault()
                                if (tenant && user && pass) {
                                    setError(false)
                                    setCreateLoading(true)
                                    let res = await createAccount(tenant, user, pass)
                                    if (res) {
                                        setTenant('')
                                        setUser('')
                                        setPass('')
                                        setCreateLoading(false)
                                        toast.success(`Account Creation Successful`, {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "dark",
                                        });
                                        navigate('/login')
                                    } else {
                                        setCreateLoading(false)
                                        toast.error(`Account Creation Failed`, {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "dark",
                                        });
                                    }
                                } else {
                                    setError(true)
                                }
                            }} type='submit'>Create</Button>
                        </div>
                        {error && <div style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>Please Fill Out All Fields...</div>}

                    </form>
                </div >
            </Paper >
        </>
    ) : <Paper id='account-creation' elevation={5}><CircularProgress size={50}></CircularProgress></Paper>
}