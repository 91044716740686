import React, { useEffect, useState } from 'react';
import './Login.css'
import { Button, Paper, TextField, Modal } from '@mui/material';
import { Route, Routes, useNavigate, } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Login({ setLoggedIn, email, setEmail, password, setPassword, handleLogin, loginError, setLoginError, missingPassword, setMissingPassword, missingEmail, setMissingEmail }) {
    const navigate = useNavigate()

    const [openCreateAccount, setOpenCreateAccount] = useState(false);

    useEffect(() => {
        setLoggedIn(false)
    }, [])
    async function loginSuccess() {
        setMissingEmail(false)
        setMissingPassword(false)
        setLoginError(false)
        if (email && password) {
            setMissingPassword(false)
            setMissingEmail(false)
            let pass = await handleLogin()
            if (!pass) {
                console.log('Incorrect Login...')
                setLoginError(true)
                setPassword('')
            } else {
                console.log('Successfully Logged In...')
                setLoginError(false)
                setPassword('')
            }
        }
        if (!email) {
            setMissingEmail(true)
        }
        if (!password) {
            setMissingPassword(true)
        }
    }
    return (
        <>
            <ToastContainer className='toast' />
            <Paper id='login-page'>
                <div className='login-inner-container'>
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        loginSuccess()
                    }}>
                        <div className='login-text'>Welcome, Please Sign In</div>
                        <div className='login-fields-container'>
                            <TextField className='login-fields' label='Email' onChange={(e) => setEmail(e.target.value)} value={email}></TextField>
                            {missingEmail && <div className='login-failed'>Please Enter a Valid Email...</div>}
                            <TextField className='login-fields' label='Password' type='password' onChange={(e) => setPassword(e.target.value)} value={password}></TextField>
                            {missingPassword && <div className='login-failed'>Please Enter a Password...</div>}
                            <Button variant='contained' type='submit' onClick={(e) => {
                                e.preventDefault()
                                loginSuccess()
                            }}>Sign In</Button>
                            <Button variant='contained' type='submit' onClick={(e) => {
                                e.preventDefault()
                                navigate('/account-creation')
                            }}>Create Account</Button>
                            {loginError && <div className='login-failed'>Incorrect Login, Please Try Again...</div>}
                        </div>
                    </form>
                </div >
            </Paper>
        </>
    )
}

