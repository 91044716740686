import './Navbar.css'
import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Navbar() {
    const navigate = useNavigate()
    const location = useLocation()

    return (
        <div >
            <Paper className='navbar-container' elevation={5}>
                <List>
                    <ListItem>
                        <ListItemButton onClick={() => navigate('/')} selected={location.pathname === '/'}><Typography>Sharc Info</Typography></ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => navigate('/livechart')} selected={location.pathname === '/livechart'} ><Typography>Live Chart</Typography></ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => navigate('/auto-adopt')} selected={location.pathname === '/auto-adopt'}><Typography>Auto Adopt</Typography></ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => navigate('/adopt')} selected={location.pathname === '/adopt'} ><Typography>Adopt-a-Sharc</Typography></ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => navigate('/metrics')} selected={location.pathname === '/metrics'} ><Typography>User Metrics</Typography></ListItemButton>
                    </ListItem>
                </List>
            </Paper>
        </div>
    )
}