import './Header.css'
import { Button, Paper } from '@mui/material';
import Cookies from 'js-cookie';
import { Modal } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';

export default function Header({ useDark, setUseDark, setLoggedIn, loggedIn, endpointsV2 }) {
    const [openSignOut, setOpenSignOut] = useState(false);

    function handleOpenSignOut() {
        setOpenSignOut(false)
    }
    async function signout() {
        let cookie = Cookies.get('authToken')
        try {
            let res = await axios.post(endpointsV2.logout, {}, { headers: { 'Authorization': `Token ${cookie}` } })
            setLoggedIn(false)
            setOpenSignOut(false)
            Cookies.remove('authToken')
            console.log('Logging Out..')
            window.location.reload()
        } catch (err) {
            console.log(err)
            console.log('Failed to Log Out, Removing Cookie...')
            setLoggedIn(false)
            setOpenSignOut(false)
            Cookies.remove('authToken')
            console.log('Logging Out..')
        }
    }

    return (
        <Paper id="header">
            <Button onClick={(e) => setUseDark(!useDark)}>{useDark ? 'Light Mode' : 'Dark Mode'}</Button>
            {loggedIn && <Button onClick={() => setOpenSignOut(true)}>Sign Out</Button>}
            <Modal open={openSignOut} onClose={handleOpenSignOut}>
                <Paper className='modal-inside-paper'>
                    <div className='modal-text'>Are you sure you want to sign out?</div>
                    <div className='button-form'>
                        <Button variant='contained' onClick={handleOpenSignOut}>No</Button>
                        <Button variant='contained' onClick={signout}>Yes</Button>
                    </div>
                </Paper>
            </Modal>
        </Paper>
    )
}