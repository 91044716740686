import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import './LiveChart.css'
let i = 0
export default function LiveChart({ sensorData, selectedSharc, selectedSensors }) {
    const [chartData, setChartData] = useState([])
    useEffect(() => {
        if (selectedSharc && sensorData[selectedSharc] && sensorData[selectedSharc][selectedSensors[selectedSharc]] && chartData.length > 49) {
            let newChartData = chartData
            newChartData.shift()
            newChartData.push({ x: i += 1, y: sensorData[selectedSharc][selectedSensors[selectedSharc]].value })
            setChartData(newChartData)
        } else if (selectedSharc && sensorData[selectedSharc] && sensorData[selectedSharc][selectedSensors[selectedSharc]]) {
            let newChartData = chartData
            newChartData.push({ x: i += 1, y: sensorData[selectedSharc][selectedSensors[selectedSharc]].value })
            setChartData(newChartData)
        }
    }, [sensorData])

    useEffect(() => {
        setChartData([])
        i = 0;
    }, [selectedSharc])
    return (
        <>
            <Paper id="live-chart" sx={{ width: '100%' }} elevation={5}>
                {selectedSharc && sensorData[selectedSharc] && sensorData[selectedSharc][selectedSensors[selectedSharc]] ?
                    < LineChart
                        xAxis={[{
                            dataKey: 'x',
                            showMark: false
                        }]}
                        series={[
                            {
                                dataKey: 'y',
                                showMark: false,
                                curve: ['s0', 's1'].includes(selectedSensors[selectedSharc]) ? 'step' : 'natural'
                            },
                        ]}
                        dataset={chartData}
                        disableLineItemHighlight={true}
                        margin={{ left: 100 }}
                    />
                    : <div className='chart-error'>No Chart Data Available</div>
                }
            </Paper>
        </>
    )
}