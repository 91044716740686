import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { Paper } from '@mui/material';
import '../Device List/SharcList.css'
import RedDot from '../../assets/red.png'
import GreenDot from '../../assets/green.png'
import CircularProgress from '@mui/material/CircularProgress';
import './Adopt.css'
import { Button, Modal, TextField } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AdoptList({ adoptableSharcs, setSelectedSharc, selectedSharc, hitEndpointsV2, listLoading, adoptSharc }) {


    const [openAdopt, setOpenAdopt] = useState(false)
    const [adoptionKey, setAdoptionKey] = useState('')
    const [adoptLoading, setAdoptLoading] = useState(false)
    const [showMessage, setShowMessage] = useState(false)



    useEffect(() => {
        setSelectedSharc('')
    }, [])

    return (!listLoading && !adoptLoading) ? (
        <>
            <ToastContainer className='toast' />
            <Modal open={openAdopt} onClose={() => setOpenAdopt(false)}>
                <Paper className='modal-inside-paper'>
                    <div className='modal-text'>Please Provide Adoption Key for {selectedSharc}</div>
                    <div className="cener-text-field" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <TextField label='Key' onChange={(e) => setAdoptionKey(e.target.value)} value={adoptionKey}></TextField>
                        {showMessage && <div className='adopt-key-error'>You must provide an adoption key</div>}
                    </div>
                    <div className='button-form'>
                        <Button variant='contained' onClick={() => {
                            setShowMessage(false)
                            setAdoptionKey('')
                            setOpenAdopt(false)
                        }}>Cancel</Button>
                        <Button variant='contained' onClick={async () => {
                            if (adoptionKey === '') {
                                setShowMessage(true)
                                return;
                            } else {
                                setShowMessage(false)
                                setAdoptLoading(true)
                                try {

                                    let res = await adoptSharc(selectedSharc, adoptionKey)
                                    if (res?.data?.result_code === 0) {
                                        toast.success('Adoption Successful', {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "dark",
                                        });
                                        setAdoptLoading(false)
                                        setAdoptionKey('')
                                        console.log('Adoption Success', selectedSharc)
                                    } else {
                                        setShowMessage(false)
                                        setAdoptLoading(false)
                                        setAdoptionKey('')
                                        toast.error(`Adoption Failed: [Status: ${res?.data?.status}, Result Code: ${res?.data?.result_code}]`, {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "dark",
                                        });
                                        console.log('Adoption Failed,', `Status: ${res?.data?.status}, Result Code: ${res?.data?.result_code}`)
                                    }
                                    setOpenAdopt(false)
                                } catch (err) {
                                    toast.error(`API Error: ${err.message}`, {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "dark",
                                    });
                                }
                            }
                        }}>Adopt</Button>
                    </div>
                </Paper>
            </Modal>
            {adoptableSharcs.length > 0 && <Paper className='sharc-list' elevation={5}>
                <List className='sharc-list-mui' style={{ maxHeight: '95%', overflow: 'auto' }}>
                    {adoptableSharcs.sort((x, y) => Number(y.avail) - Number(x.avail)).map((sharc) => (
                        <ListItem key={sharc.serial}>
                            <ListItemButton onClick={(e) => {
                                if (selectedSharc !== sharc.serial) {
                                    setSelectedSharc(sharc.serial)
                                    // hitEndpointsV2('single', sharc.serial)
                                }
                            }} selected={sharc.serial === selectedSharc}>
                                <div className='list-item-button-internal'>
                                    <div className='list-item-serial'><img id='avail-dot' src={sharc.avail ? GreenDot : RedDot} />{sharc.serial}</div>
                                    <div className='button-container'>
                                        <Button variant='contained' onClick={(e) => {
                                            e.preventDefault()
                                            setOpenAdopt(true)
                                        }} className='reboot-buttons'>Adopt</Button>
                                    </div>
                                </div>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Paper >}
            {adoptableSharcs.length === 0 && <Paper sx={{ width: '100%' }} elevation={5}>
                <div className='sharc-text'>No Adoptable Sharcs </div>
            </Paper>
            }
        </>
    ) : <Paper className='sharc-list' elevation={5} sx={{ width: '20vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress size={50}></CircularProgress></Paper>
}