import './Footer.css'
import github from '../assets/github.png'
import githubWhite from '../assets/github_white.png'
import linkedin from '../assets/linkedin.png'
import { Paper } from '@mui/material';
export default function Footer({ useDark }) {
    return (
        <Paper id="footer" elevation={5}>
            <div className='link-container'>
                <div className='link-container-div'><a href='https://github.com/SharcTech/sharc-support' target='_blank'><img className='link-image' src={useDark ? githubWhite : github} /></a></div>
                <div className='link-container-div'><a href='https://www.linkedin.com/company/mriiot' target='_blank'><img className='link-image' src={linkedin} /></a></div>
            </div>
        </Paper>
    )
}